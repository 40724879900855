<template>
    <main class="swcluster-main" id="swcluster-datasandbox-awsglue">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <div class="header-row">
                    <div class="header-actions">
                        <button class="btn-action btn-action-back" @click="goBack">
                            <i class="icon-arrow"></i>
                            <strong class="text">이전</strong>
                        </button>
                    </div>
                    <CommonBreadcrumb />
                </div>
                <h2 class="page-title">&nbsp;</h2>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <!-- content-section:결과 -->
                <article class="content-section section-metadata">
                    <header class="section-header">
                        <h3 class="title title-sm">Glue Crawler</h3>
                    </header>
                    <div class="metadata-container">
                        <div class="metadata-item">
                            <span class="metadata-title">이름</span>
                            <p class="metadata-content">{{ params.name }}</p>
                        </div>
                        <!--                        <div class="metadata-item">-->
                        <!--                            <span class="metadata-title">IAM role</span>-->
                        <!--                            <p class="metadata-content">AWSGlueServiceRole-Crawler</p>-->
                        <!--                        </div>-->
                        <div class="metadata-item">
                            <span class="metadata-title">데이터 베이스</span>
                            <p class="metadata-content">{{ params.db }}</p>
                        </div>
                        <div class="metadata-item">
                            <span class="metadata-title">상태</span>
                            <p class="metadata-content">{{ params.state }}</p>
                        </div>
                    </div>
                </article>
                <!-- content-section:결과 -->
                <article v-if="items.length > 0" class="content-section section-divider section-result">
                    <header class="section-header d-flex">
                        <h3 class="title title-sm-light">실행이력</h3>
                    </header>
                    <div class="table-container">
                        <div class="table table-outline">
                            <table>
                                <colgroup>
                                    <col style="width: 16%" />
                                    <col style="width: 16%" />
                                    <col style="width: 9.5%" />
                                    <col style="width: 12%" />
                                    <col style="width: 11%" />
                                    <col style="width: auto" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">Start Time (UTC)</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">End time (UTC)</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">Duration</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">Status</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">DPU hours</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">Table changes</span></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in renderItems" :key="index">
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ timestampToDateFormat(item.stTm, 'yyyy.MM.dd hh:mm') }}</span>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ timestampToDateFormat(item.edTm, 'yyyy.MM.dd hh:mm') }}</span>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ (item.edTm - item.stTm) / 1000 }}s</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ item.state }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ fixedNumber(item.dpuHour, 8) }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ item.summary ? item.summary.split('\\"').join('') : '-' }}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- pagination -->
                        <CommonPagination :paging="paging" :page-func="pageFunc" />
                    </div>
                </article>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-row-container bottom-component">
                    <div class="bottom-side">
                        <button class="btn-action btn-primary" @click="execCrawler"><span class="text">Crawler 실행</span></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->
    </main>
</template>

<script>
import {computed, reactive, ref} from 'vue';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {fixedNumber, getItem, getItems, isSuccess, lengthCheck, paginate, setParams, timestampToDateFormat} from '@/assets/js/common.utils';
import CommonPagination from '@/components/common/CommonPagination';
import {goAws} from '@/assets/js/modules/aws/module';
import {useRoute, useRouter} from 'vue-router';
import ApiService from '@/assets/js/api.service';
import {hideLoading, showLoading} from '@/assets/js/common.alert';

export default {
    name: 'GlueCrawler',
    components: {CommonPagination, CommonBreadcrumb},
    setup: function () {
        const route = useRoute();
        const router = useRouter();
        const crawlerName = computed(() => route.params.crawlerName);

        const isReady = ref(false);

        const params = reactive({
            name: '',
            db: '',
            state: '',
        });

        const items = ref([]);
        const paging = reactive({
            pageNo: 1,
            pageSize: 10,
            totalCount: computed(() => items.value.length),
        });

        const renderItems = computed(() => paginate(items.value, paging.pageNo, paging.pageSize));

        const pageFunc = pageNo => {
            paging.pageNo = pageNo;
        };

        const getCrawler = async () => {
            if (crawlerName.value) {
                showLoading();
                try {
                    const res = await ApiService.get('/v1/aws/glue/crawlers', crawlerName.value);
                    if (lengthCheck(res)) {
                        const item = getItem(res);
                        setParams(params, item);

                        const response = await ApiService.query(`/v1/aws/glue/crawlers/${crawlerName.value}/histories`, {});

                        if (lengthCheck(response)) {
                            items.value = getItems(response);
                        }
                        // if (item.lastInfo) {
                        //     items.value = [item.lastInfo];
                        // }
                    }
                } catch (e) {
                    console.error(e);
                }

                isReady.value = true;
                hideLoading();
            }
        };

        const goBack = e => {
            if (e.ctrlKey) {
                window.open('/sandbox/glue');
            } else {
                router.push({name: 'Glue'});
            }
        };

        const execCrawler = async () => {
            if (confirm(`Glue Crawler (${crawlerName.value})을 실행하시겠습니까?`)) {
                showLoading();
                const res = await ApiService.post(`/v1/aws/glue/crawlers/${crawlerName.value}`, {});
                if (isSuccess(res)) {
                    const response = await ApiService.query(`/v1/aws/glue/crawlers/${crawlerName.value}/histories`, {});
                    if (lengthCheck(response)) {
                        items.value = getItems(response);
                    }
                } else {
                    alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
                }
                hideLoading();
            }
        };

        getCrawler().then(() => {});

        return {
            params,
            items,
            renderItems,
            paging,
            pageFunc,
            execCrawler,
            goBack,
            goAws,
            fixedNumber,
            timestampToDateFormat,
        };
    },
};
</script>
